//Colors
	$b: #000;
	$w: #fff;
	$red: #bb0a30;
	$darkred:#961423;
	$sport:#E30613;
	$blue:#1c4570;
	$lblue:#2f6297;
	$lgrey: #cfcfcf;
	$lightgrey:#f2f2f2;
	$grey: #b3b3b3;
	$beige: #b6b1a9;
	$dark:#1a1a1a;
	$middark:#333;

	$success:#090;
	$error:#bb0a30;

	$clear:transparent;
//Font sizes
	$huge:6em;
	$big:4em;
	$medium:2em;
	$default:1em;
//Layout Sizes
	$large: 1200px;
	$desktop: 992px;
	$tablet: 768px;
	$tmobi: 650px; 
	$mobi: 480px; 
	$smobi: 420px; 
//Percentages
	$p1: 100%;
	$p2: 50%;
	$p3: 33.33333%;
	$p4: 25%;
	$p5: 20%;
	$p8: 12.5%;
	$p10: 10%;
//Easing
	$ease: cubic-bezier(0.750, 0.020, 0.500, 1.000);
