@font-face {
    font-family: 'Audi';
    src: url(fonts/subset/AudiTypeScreen-Normal.woff2) format('woff2'),
    url(fonts/subset/AudiTypeScreen-Normal.woff) format('woff'),
    url(fonts/subset/AudiTypeScreen-Normal.ttf) format('ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Audi';
    src: url(fonts/subset/AudiTypeScreen-Bold.woff2) format('woff2'),
    url(fonts/subset/AudiTypeScreen-Bold.woff) format('woff'),
    url(fonts/subset/AudiTypeScreen-Bold.ttf) format('ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Audi';
    src: url(fonts/subset/AudiTypeExtended-Normal.woff2) format('woff2'),
    url(fonts/subset/AudiTypeExtended-Normal.woff) format('woff'),
    url(fonts/subset/AudiTypeExtended-Normal.ttf) format('ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Audi';
    src: url(fonts/subset/AudiTypeExtended-Bold.woff2) format('woff2'),
    url(fonts/subset/AudiTypeExtended-Bold.woff) format('woff'),
    url(fonts/subset/AudiTypeExtended-Bold.ttf) format('ttf');
    font-weight: 900;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/subset/AM.woff2) format('woff2'),
    url(fonts/subset/AM.woff) format('woff'),
    url(fonts/subset/AM.ttf) format('ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/subset/AB.woff2) format('woff2'),
    url(fonts/subset/AB.woff) format('woff'),
    url(fonts/subset/AB.ttf) format('ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/subset/AM.woff2) format('woff2'),
    url(fonts/subset/AM.woff) format('woff'),
    url(fonts/subset/AM.ttf) format('ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/subset/AB.woff2) format('woff2'),
    url(fonts/subset/AB.woff) format('woff'),
    url(fonts/subset/AB.ttf) format('ttf');
    font-weight: 900;
}