@import 'scss/fonts';
@import 'scss/variables';
@import 'scss/prefix';
@import 'scss/slick';

@keyframes react-placeholder-pulse {
	0% { opacity: .6; }
	50% { opacity: 1; }
	100% { opacity: .6; } 
}
.place {
	position: relative;
	z-index:0;
	animation: react-placeholder-pulse 1.5s infinite;
}
.imageloader {
	display:block;
}

html {
	body {
		@extend html;
		font-family:Audi,AudiAR,Verdana,sans-serif;
		font-weight: 400;
		margin:0 auto;
	}
}
* {
	outline:0!important;
	box-sizing:border-box;
}

.swiper-container {
	overflow:unset!important;
	--swiper-pagination-color:rgba(0,0,0,.5);
}
.swiper-slide {
	padding:0;
	@media (max-width:540px) {
		width:75vw!important;		
	}
	img {
		width:$p1;
		display:block;
	}
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
	bottom:-28px;
}

.feature {
	overflow: hidden;
	padding:0px 32px 32px;
	&.pad-dot {
		padding-bottom:0;
	}
}
.feature-item {
    cursor: move; 
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.feature-item:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.feature-item.white {
	color:$w;
}
.feature-item.black {
	color:$b;
}

.banner {
	position: relative;
	.banner-content {
		position: absolute;
		top:0;
		left:0;
		width:$p1;
		text-align:center;
		color:currentColor;
	    height: $p1;
		padding-top:50px;
		z-index:3;
		@include bdesktop {
			padding-top:40px;
		}
		@include desktop {
			padding-top:30px;
		}
		&.rel {
			position: relative;
		}
		.inset {
			padding:10px 20px 20px;
			position: relative;
			z-index:1;
			@include bdesktop {
				padding:10px 10px 20px;	
			}
		}
		h1 {
			margin:0;
			font-size:20px;
			font-weight:900;
    		overflow: hidden;
			@include bdesktop {
				font-size:18px;	
			}
			@include desktop {
				font-size:18px;		
			}
			@include mid {
				font-size:16px;	
			}
		}
		h2 {
			margin:0 0 10px;
			font-size:14px;
			line-height: 1.6;
			@include bdesktop {
				font-size:13px;	
			}
			@include desktop {
				font-size:14px;	
			}
			@include mid {
				font-size:13px;	
				line-height:1.5;
			}
			@include tablet {
				font-size:12px;		
			}
		}
		hr {
			width:50%;
			opacity: 1;
			border:0;
			height:1px;
			background: currentColor;
			margin:8px auto;
		}
		.infobar {
			background: $b;
		    color: $w;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    width: $p1;
		    padding: 10px;
		    // text-transform: uppercase;
		    font-size: 13px;
		    font-weight: 500;
			p {
				margin:0;
			}
		}
		&.fill {
			height:$p1;
			padding-top:0;
			a {
				opacity:0;
				color:$w!important;
				text-decoration: none!important;
				background:rgba(0,0,0,.6);
				width:$p1;
				height:$p1;
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight:900;
				font-size:22px;
				@include transition(opacity .4s $ease);
				&:hover {
					opacity:1;
				}
				@include mid {
					font-size:16px;	
				}
				@include tablet {
					white-space: nowrap;
					opacity:1;
					font-size:16px;		
					padding:18px 32px;
					justify-content: flex-start;
					align-items: flex-end;
					background: rgba(0,0,0,3);
					background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
					background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
					background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000050",endColorstr="#000000",GradientType=1);
				}
			}
			&.fill-white {
				a {
					background:rgba(255,255,255,.8);
					color:$b;
					@include tablet {
						color:$w;
						background: transparent;
					}
				}
			}
		}
	}
}
a.cta {
	display:block;
	width:$p1;
	padding:16px 20px;
	color:$w;
	font-size:16px;
	background:$red;
	text-decoration: none;
	position: relative;
	z-index:1;
	text-align:center;
}






feature.arabic {
	.feature-item {
		direction:rtl;
	}
}


